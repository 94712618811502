/**
 * @module NatModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { IAviDataGridConfig }
    from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { NatPolicyItem } from 'ajs/modules/policies/factories/nat-policy/nat-policy.item.factory';
import {
    NatRuleConfigItem,
} from 'ajs/modules/policies/factories/nat-policy/nat-rule.config-item.factory';

import { NatPolicy } from 'object-types';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './nat-policy-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Nat policy modal.
 *
 * @author Guru Prasad H K
 */
@Component({
    selector: 'nat-policy-modal',
    templateUrl: './nat-policy-modal.component.html',
})
export class NatPolicyModalComponent implements AfterViewInit {
    /**
     * NatPolicyItem instance.
     */
    @Input()
    public editable: NatPolicyItem;

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit = new EventEmitter<void>();

    /**
     * Template ref for enabled checkbox row.
     */
    @ViewChild('enabledColumnTemplateRef')
    public enabledColumnTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Object type for NAT policy.
     */
    public readonly objectType = NatPolicy;

    /**
     * Layout for NatPolicy modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Nat Rules grid config.
     */
    public natRulesGridConfig: IAviDataGridConfig;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Setting NAT Rules Grid Config.
     */
    public ngAfterViewInit(): void {
        const {
            l10nService,
        } = this;

        this.natRulesGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.nameLabel),
                    id: 'name',
                    transform({ config }) {
                        return config.name || '';
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.ruleStatus),
                    id: 'enable',
                    templateRef: this.enabledColumnTemplateRef,
                },
            ],
            singleactions: [
                {
                    label: l10nService.getMessage(l10nKeys.editLabel),
                    shape: 'pencil',
                    onClick: (natRule: NatRuleConfigItem) => {
                        this.editNatRule(natRule);
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.deleteLabel),
                    shape: 'trash',
                    onClick: (natRule: NatRuleConfigItem) => {
                        this.deleteNatRule(natRule);
                    },
                },
            ],
        };
    }

    /**
     * Opens NatRule modal.
     */
    public createNatRule(): void {
        this.editable.addNatRule();
    }

    /**
     * Fires on submit.
     */
    public submit(): void {
        this.onSubmit.emit();
    }

    /**
     * Handler for Nat Rule edit.
     */
    private editNatRule(natRule: NatRuleConfigItem): void {
        this.editable.editSyslogServer(natRule);
    }

    /**
     * Handler for Nat Rule delete.
     */
    private deleteNatRule(natRule: NatRuleConfigItem): void {
        const { rules } = this.editable.config;

        rules.removeByMessageItem(natRule);
    }
}
