/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
    OnInit,
    Type,
} from '@angular/core';

import {
    IAwsAvailabilityZone,
    IAwsCredentialsConfig,
    IAwsRegion,
    IAwsVpc,
    IpamDnsAwsProfileConfigItem,
} from 'ajs/modules/ipam/factories/ipam-dns-aws-profile.config-item.factory';

import {
    IAwsZoneNetwork,
    IpamDnsType,
    IProxyConfiguration,
} from 'generated-types';

import {
    IpamDnsAwsProfile,
    ProxyConfiguration,
} from 'object-types';

import { isEmpty } from 'underscore';
import { L10nService } from '@vmw/ngx-vip';
import { Observable } from 'rxjs';
import { ipamDnsProfileTypeHash } from 'ajs/modules/ipam/factories/ipam-dns-profile.types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IEditCredentialsConfig } from 'ng/modules/avi-forms/components/credentials-verification';
import { ITEM_ID_TOKEN } from 'ng/shared/shared.constants';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';
import { IpamDnsAwsCredentialsDialogComponent } from
    './ipam-dns-aws-credentials-dialog/ipam-dns-aws-credentials-dialog.component';
import * as l10n from './ipam-dns-aws-profile-config.l10n';
import './ipam-dns-aws-profile-config.component.less';

const { ENGLISH, ...l10nKeys } = l10n;

/**
 * @description IpamDnsAwsProfile configuration component.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'ipam-dns-aws-profile-config',
    templateUrl: './ipam-dns-aws-profile-config.component.html',
})
export class IpamDnsAwsProfileConfigComponent implements OnInit {
    /**
     *  IpamDnsAwsProfile ConfigItem instance.
     */
    @Input()
    public editable: IpamDnsAwsProfileConfigItem;

    /**
     * ProxyConfiguration configItem instance.
     */
    @Input()
    public proxyConfigItem: MessageItem<IProxyConfiguration>;

    /**
     * Type of IpamDnsProviderProfile.
     */
    @Input()
    public type: IpamDnsType;

    /**
     * Object-types used in template.
     */
    public readonly objectTypes = {
        [IpamDnsAwsProfile]: IpamDnsAwsProfile,
        [ProxyConfiguration]: ProxyConfiguration,
    };

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * True if ipam profile is being edited.
     */
    public readonly isEditing: boolean;

    /**
     * Credentials config passed to the CredentialsVerification component.
     */
    public editAwsProfileCredentialsConfig: IEditCredentialsConfig;

    /**
     * True if credentials is set by the user.
     */
    public connected = false;

    /**
     * IpamDnsProfile type hash.
     */
    public readonly ipamDnsTypes = ipamDnsProfileTypeHash;

    /**
     * List of AWS Regions.
     */
    public regions: IAwsRegion[];

    /**
     * True when proxy settings are configured for aws.
     */
    public useProxy = false;

    /**
     * True when AWS Regions are being loaded.
     */
    public regionsLoading = false;

    /**
     * True when AWS IAM AssumeRoles are being loaded.
     * Call to get iamAssumeRoles is slow & will take some time.
     */
    public iamAssumeRolesLoading = false;

    /**
     * NgModel of useIamAssumeRole checkbox.
     * If true, iamAssumeRoles dropdown will be displayed.
     */
    public useIamAssumeRole = false;

    /**
     * IAM AssumeRoles Dropdown options.
     */
    public iamRolesDropdownOptions: IAviDropdownOption[] = [];

    /**
     * VPC Dropdown options.
     */
    public vpcDropdownOptions: IAviDropdownOption[] = [];

    /**
     * AWS Availability zones.
     */
    public availabiltyZones: IAwsAvailabilityZone[] = [];

    /**
     * AWS domain list.
     */
    public domains: string[] = [];

    constructor(
        l10nService: L10nService,
        @Inject(ITEM_ID_TOKEN)
        private readonly ipamProfileId: string,
    ) {
        l10nService.registerSourceBundles(ENGLISH);

        this.isEditing = Boolean(ipamProfileId);
    }

    /** @override */
    public ngOnInit(): void {
        this.setRegions().then(() => this.setEditAwsProfileCredentialsConfig());

        if (this.isEditing) {
            this.useProxy = !isEmpty(this.proxyConfigItem.config);
            this.useIamAssumeRole = Boolean(this.editable.config.iam_assume_role);

            if (this.useIamAssumeRole) {
                this.setIamAssumeRolesDropdownOptions();
            }

            this.setVpcDropdownOptions();

            if (this.type === IpamDnsType.IPAMDNS_TYPE_AWS) {
                this.setAvailabilityZones();
            } else {
                this.getAwsDomains();
            }

            this.connected = true;
        }
    }

    /**
     * Sets IamAssumeRole dropdown options.
     */
    public async setIamAssumeRolesDropdownOptions(): Promise<void> {
        if (this.iamRolesDropdownOptions.length) {
            return;
        }

        this.iamAssumeRolesLoading = true;

        try {
            const iamAssumeRoles = await this.editable.getIamAssumeRoles(
                this.proxyConfigItem.config,
                this.ipamProfileId,
            );

            this.iamRolesDropdownOptions =
                iamAssumeRoles.map(({ account, role }) => {
                    return createDropdownOption(role, role, account);
                });
        } catch (e) {
            console.error(e);
        } finally {
            this.iamAssumeRolesLoading = false;
        }
    }

    /**
     * Sets AWS AvailabiltyZones.
     */
    public async setAvailabilityZones(): Promise<void> {
        try {
            this.availabiltyZones = await this.editable.getAvailabilityZones(
                this.proxyConfigItem.config,
                this.ipamProfileId,
            );
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Handler for zone add event.
     */
    public addAvailabilityZone(): void {
        this.editable.config.zones.add();
    }

    /**
     * Handler for zone delete event.
     */
    public deleteAvailabilityZone(zone: MessageItem<IAwsZoneNetwork>): void {
        this.editable.config.zones.removeByMessageItem(zone);
    }

    /**
     * Creates the config object passed to the CredentialsVerification component for editing
     * credentials.
     */
    private setEditAwsProfileCredentialsConfig(): void {
        const { config } = this.editable;
        const { config: proxyConfig } = this.proxyConfigItem;

        const editCredentialsConfig: IAwsCredentialsConfig = {
            password: '',
            username: config.access_key_id,
            region: config.region,
            useIamRoles: config.use_iam_roles,
            useProxy: this.useProxy,
            proxy: {
                ...proxyConfig,
            },
        };

        this.editAwsProfileCredentialsConfig = {
            editCredentialsDialog: IpamDnsAwsCredentialsDialogComponent as Type<Component>,
            editCredentialsDialogProps: {
                config: editCredentialsConfig,
                isEditing: this.isEditing,
                type: this.type,
                regions: this.regions,
                submit$: new Observable<void>(subscriber => {
                    this.editable.verifyAwsCredentials(editCredentialsConfig, this.ipamProfileId)
                        .then((vpcs: IAwsVpc[]) => {
                            subscriber.next();
                            subscriber.complete();

                            this.editable.setAwsLoginCredentials(editCredentialsConfig);
                            this.proxyConfigItem.updateConfig(editCredentialsConfig.proxy);
                            this.useProxy = !isEmpty(this.proxyConfigItem.config);
                            this.setEditAwsProfileCredentialsConfig();
                            this.setVpcDropdownOptions(vpcs);

                            this.connected = true;

                            this.editable.clearUsableNetworks();
                            this.editable.clearUsableDomains();
                        }).catch(error => subscriber.error(error));
                }),
                onCancel: () => {
                    this.editable.cancelAwsVerifyCredentials();

                    this.setEditAwsProfileCredentialsConfig();
                },
            },
        };
    }

    /**
     * Sets AWS Regions.
     */
    private async setRegions(): Promise<void> {
        try {
            this.regionsLoading = true;

            this.regions = await this.editable.getRegions();

            this.regionsLoading = false;
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Sets VPC dropdown options.
     */
    private async setVpcDropdownOptions(vpcs?: IAwsVpc[]): Promise<void> {
        if (!vpcs) {
            try {
                vpcs =
                    await this.editable.getVpcs(this.proxyConfigItem.config, this.ipamProfileId);
            } catch (e) {
                console.error(e);
            }
        }

        this.vpcDropdownOptions = vpcs.map(({ id, name, cidr }) => {
            return createDropdownOption(id, `${name} - ${cidr}`);
        });
    }

    /**
     * Loads AWS Domains.
     */
    private async getAwsDomains(): Promise<void> {
        try {
            this.domains =
                await this.editable.getDomains(this.proxyConfigItem.config, this.ipamProfileId);
        } catch (e) {
            console.error(e);
        }
    }
}
