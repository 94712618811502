/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ApplicationProfileModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { CompressionFilter } from 'object-types';
import { ICompressionFilter } from 'generated-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IpAddrConfigItem } from 'ajs/modules/data-model/factories/ip-addr.config-item.factory';

import {
    IpAddrPrefixConfigItem,
} from 'ajs/modules/data-model/factories/ip-addr-prefix.config-item.factory';

import {
    IpAddrRangeConfigItem,
} from 'ajs/modules/data-model/factories/ip-addr-range.config-item.factory';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import * as l10n from '../application-profile.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TCompressionFilterPartial = Omit<ICompressionFilter,
'ip_addr_prefixes' |
'ip_addr_ranges' |
'ip_addrs'
>;

/**
 * Extended ICompressionFilter Interface to add UI only field.
 */
interface IExtendedCompressionFilter extends TCompressionFilterPartial {
    ip_addr_prefixes: RepeatedMessageItem<IpAddrPrefixConfigItem>
    ip_addr_ranges: RepeatedMessageItem<IpAddrRangeConfigItem>
    ip_addrs: RepeatedMessageItem<IpAddrConfigItem>
    ipAddrsStrings?: string[], // UI only field.
}

/**
 * @desc CompressionFilter MessageItem class.
 * @author Nisar Nadaf
 */
export class CompressionFilterConfigItem extends
    withFullModalMixin(MessageItem)<IExtendedCompressionFilter> {
    public static ajsDependencies = [
        'l10nService',
        'RangeParser',
    ];

    /**
     * l10nService instance to register source bundles and get keys from source bundles.
     */
    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: CompressionFilter,
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);
        this.l10nService = this.getAjsDependency_('l10nService');
    }

    /**
     * Method used to import lazy-loaded modal component.
     */
    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { CompressionFilterModalComponent } = await import(
            /* webpackChunkName: "compression-filter-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/application-profile-modal/app-profile-http-compression/compression-filter-modal/compression-filter-modal.component'
        );

        return CompressionFilterModalComponent as Type<Component>;
    }

    /** @override */
    public getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.compressionFilterleBreadcrumbTitle);
    }

    /**
     * Convert 'addrs', 'ranges', and 'prefixes' objects to string values required on UI.
     * @override
     */
    public modifyConfigDataAfterLoad(): void {
        const { ip_addr_prefixes: prefixes, ip_addr_ranges: ranges, ip_addrs: addrs } = this.config;

        super.modifyConfigDataAfterLoad();

        const ipAddrStrings: string[] = [];

        addrs.config.forEach(({ config: { addr } }) => {
            if (addr) {
                ipAddrStrings.push(addr);
            }
        });

        ranges.config.forEach(({ config: { begin, end } }) => {
            if (begin) {
                ipAddrStrings.push(`${begin.config.addr}-${end.config.addr}`);
            }
        });

        prefixes.config.forEach(({ config: { ip_addr, mask } }) => {
            if (ip_addr.config) {
                ipAddrStrings.push(`${ip_addr.config.addr}/${mask}`);
            }
        });

        this.config.ipAddrsStrings = ipAddrStrings;
    }

    /**
     * Convert IP address string values to 'addrs', 'ranges', and 'prefixes' objects.
     * @override
     */
    public modifyConfigDataBeforeSave(): void {
        const { ipAddrsStrings } = this.config;

        const rangeParser = this.getAjsDependency_('RangeParser');

        super.modifyConfigDataBeforeSave();

        ipAddrsStrings.forEach((ip: string) => {
            const rangeOrIp = rangeParser.ipRange2Json(ip.trim());

            if (!rangeOrIp) {
                return; // Not recognized piece
            }

            if (rangeOrIp.begin) { // assume range
                this.config.ip_addr_ranges.add(rangeOrIp);
            } else if (rangeOrIp.mask) { // assume prefix
                this.config.ip_addr_prefixes.add(rangeOrIp);
            } else { // otherwise ip
                this.config.ip_addrs.add(rangeOrIp);
            }
        });

        delete this.config.ipAddrsStrings;
    }
}
