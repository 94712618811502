/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ServiceEngineModule */

import {
    Component,
    Inject,
    Input,
    OnInit,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { TrafficCaptureModalService }
    from 'ng/modules/service-engine/services/traffic-capture-modal.service';

import {
    DebugSeDataplaneFlags,
    IDebugIpAddr,
    IDebugServiceEngine,
} from 'generated-types';

import {
    CaptureFileSize,
    CaptureFilters,
    DebugServiceEngine,
    DebugVirtualServiceCapture,
} from 'object-types';

import { DefaultValues } from 'ajs/modules/core/services/default-values.service';
import { TPbEnumValueKey } from 'ajs/modules/core/services/schema-service/schema.types';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';

import {
    IObjectType,
    IStartCaptureConfig,
} from 'ng/modules/service-engine/types/service-engine.types';

import { ServiceEngineTrafficCaptureStore }
    from 'ng/modules/service-engine/component-store/service-engine-traffic-capture.store';

import './traffic-capture-modal.component.less';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import * as l10n from './traffic-capture-modal.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

enum debugIpFilter {
    ALL = 'All',
    CHOOSE_IP = 'CHOOSE_IP',
}

const objectType: IObjectType = {
    debugServiceEngine: DebugServiceEngine,
    captureFilters: CaptureFilters,
    debugVirtualServiceCapture: DebugVirtualServiceCapture,
    captureFileSize: CaptureFileSize,
};

const allowedPacketCaptureTypes = new Set<string>([
    DebugSeDataplaneFlags.DEBUG_PCAP_RX,
    DebugSeDataplaneFlags.DEBUG_PCAP_TX,
    DebugSeDataplaneFlags.DEBUG_PCAP_DROP,
    DebugSeDataplaneFlags.DEBUG_PCAP_ALL,
    DebugSeDataplaneFlags.DEBUG_PCAP_SE_IPC,
    DebugSeDataplaneFlags.DEBUG_PCAP_NAT,
    DebugSeDataplaneFlags.DEBUG_PCAP_ROUTING,
]);

/**
 * @description Component displaying current Traffic capture settings.
 * @author Suraj Kumar
 */
@Component({
    selector: 'traffic-capture-modal',
    templateUrl: './traffic-capture-modal.component.html',
})
export class TrafficCaptureModalComponent implements OnInit {
    /**
     * Selected Service Engine's name.
     */
    @Input()
    public serviceEngineName: string;

    /**
     * Object types for SE traffic capture.
     */
    public readonly objectType: IObjectType = objectType;

    /**
     * Stores all the selected packet capture types.
     */
    public selectedPacketCaptureTypesList: DebugSeDataplaneFlags[];

    /**
     * Property holding selected debugIpFilter type.
     */
    public debugIpFilterType = debugIpFilter.ALL;

    /**
     * Property holding debugIpFilter enum.
     */
    public readonly debugIpFilter = debugIpFilter;

    /**
     * Property holding debug_ip in string format for showing on UI.
     */
    public debugIpAddrString: string;

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Busy flag for rendering a spinner.
     */
    public busy = false;

    /**
     * Used to show error message if any.
     */
    public error: string;

    /**
     * Hidden option values.
     */
    public hiddenTypes: TPbEnumValueKey[] = [];

    /**
     * Service Engine's traffic capture configuration object.
     */
    public trafficCaptureConfiguration: IDebugServiceEngine;

    constructor(
        private readonly trafficCaptureModalService: TrafficCaptureModalService,
        public readonly serviceEngineTrafficCaptureStore: ServiceEngineTrafficCaptureStore,
        private readonly schemaService: SchemaService,
        @Inject(DefaultValues)
        private readonly defaultValues: DefaultValues,
        @Inject('RangeParser')
        private rangeParser: any,
        @Inject('$filter')
        private filter: any,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.addDefaultConfiguration();

        this.hiddenTypes = this.getHiddenPacketCaptureTypesEnum();
    }

    /**
     * Function to close traffic capture modal.
     */
    public handleCancel(): void {
        this.trafficCaptureModalService.closeModal();
    }

    /**
     * Called when the submit button is clicked.
     */
    public handleSubmit(): void {
        this.busy = true;
        this.error = undefined;
        this.trafficCaptureConfiguration.flags = this.selectedPacketCaptureTypesList?.map(
            element => ({ flag: element }),
        );

        delete this.trafficCaptureConfiguration.selogagent_debug;

        this.updateDebugIpAddr();
        this.serviceEngineTrafficCaptureStore.startCapture(this.getStartCaptureConfig());
    }

    /**
     * Sets debugIpAddrString to undefined when user switches between All and CHOOSE_IP.
     */
    public onDebugIpFilterChange(): void {
        this.debugIpAddrString = undefined;
    }

    /**
     * Adds default configuration for SE traffic capture configuration object.
     */
    private addDefaultConfiguration(): void {
        const defaultConfig = this.defaultValues.getDefaultItemConfigByType(
            'debugserviceengine',
        );

        defaultConfig.capture_filters = {
            src_port: undefined,
            dst_port_start: undefined,
            dst_port_end: undefined,
        };

        this.addDebugIpStringAndFilterType(defaultConfig);

        if (this.serviceEngineName) {
            defaultConfig.name = this.serviceEngineName;
        } else {
            this.serviceEngineName = defaultConfig.name;
        }

        this.trafficCaptureConfiguration = defaultConfig;
    }

    /**
     * Updates debugIpAddrString value and sets filter type of traffic capture.
     */
    private addDebugIpStringAndFilterType(debugServiceEngine: IDebugServiceEngine): void {
        if (debugServiceEngine?.debug_ip) {
            this.debugIpAddrString = this.filter('ipObjectToString')(debugServiceEngine.debug_ip);
            this.debugIpFilterType = debugIpFilter.CHOOSE_IP;
        } else {
            this.debugIpFilterType = debugIpFilter.ALL;
        }
    }

    /**
     * Get start capture configuration.
     */
    private getStartCaptureConfig(): IStartCaptureConfig {
        const startCaptureConfig: IStartCaptureConfig = {
            config: this.trafficCaptureConfiguration,
            whenSaved: () => {
                this.busy = false;
                this.trafficCaptureModalService.closeModal();
            },
            whenError: (error: string) => {
                this.busy = false;
                this.error = error;
            },
        };

        return startCaptureConfig;
    }

    /**
     * Prepares debug_ip value to be saved to begin traffic capture.
     */
    private updateDebugIpAddr(): void {
        if (this.debugIpFilterType === debugIpFilter.ALL) {
            this.trafficCaptureConfiguration.debug_ip = undefined;
        } else {
            this.trafficCaptureConfiguration.debug_ip = this.parseIpList(this.debugIpAddrString);
        }
    }

    /**
     * Parses the string to create arrays for IP addresses, ranges, and subnet masks.
     */
    private parseIpList(stringValue: string): IDebugIpAddr {
        const debug: IDebugIpAddr = {};
        const data = stringValue.split(',');

        data.forEach(addr => {
            const rangeOrIp = this.rangeParser.ipRange2Json(addr);

            if (!rangeOrIp) {
                return;
            }

            if (rangeOrIp.begin) {
                debug.ranges = debug.ranges || [];

                debug.ranges.push(rangeOrIp);
            } else if (rangeOrIp.mask) {
                debug.prefixes = debug.prefixes || [];

                debug.prefixes.push(rangeOrIp);
            } else {
                debug.addrs = debug.addrs || [];

                debug.addrs.push(rangeOrIp);
            }
        });

        return debug;
    }

    /**
     * Finds and returns Hidden Packet Capture Types enum.
     */
    private getHiddenPacketCaptureTypesEnum(): TPbEnumValueKey[] {
        const packetCaptureTypes = this.schemaService.getEnumValues('DebugSeDataplaneFlags');

        const hiddenPacketCaptureTypes = packetCaptureTypes.filter(({ value }) =>
            !allowedPacketCaptureTypes.has(value));

        const hiddenPacketCaptureTypesValues = hiddenPacketCaptureTypes.map(element =>
            element.value);

        return hiddenPacketCaptureTypesValues;
    }
}
