/**
 * @module NatModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import {
    NatPolicyModalComponent,
} from 'ng/modules/nat-policy/components/nat-policy-modal/nat-policy-modal.component';

/**
 * @desc NAT policy Collection.
 * @author Aravindh Nagarajan, Zhiqian Liu
 */
export class NatPolicyCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'natpolicy',
            windowElement: NatPolicyModalComponent,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_('NatPolicyItem');
    }
}

NatPolicyCollection.ajsDependencies = [
    'NatPolicyItem',
];
