/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

import {
    debounce,
    every,
    isUndefined,
} from 'underscore';

import {
    Collection,
    Item,
    ObjectTypeItem,
} from 'ajs/modules/data-model/factories';

import { StringService } from 'ajs/modules/core/services/string-service';

import {
    DropdownModelValue,
    IAviDropdownOption,
} from '../avi-dropdown';

import {
    AviDropdownButtonPosition,
    IAviDropdownButtonAction,
} from '../avi-dropdown-button';

import { normalizeValue } from '../../utils';
import './avi-collection-dropdown.component.less';

/**
 * Returns the dropdown option for an item.
 */
const getIDropdownItemOption = (item: Item): IAviDropdownOption => ({
    label: item.getName(),
    value: item.getRef(),
});

/**
 * Used for paginated loading from a collection.
 */
const PAGE_SIZE = 8;

/**
 * @description Dropdown component that retrieves options from a Collection.
 * @author alextsg
 */
@Component({
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AviCollectionDropdownComponent),
        },
    ],
    selector: 'avi-collection-dropdown',
    templateUrl: './avi-collection-dropdown.component.html',
})
export class AviCollectionDropdownComponent implements ControlValueAccessor, OnInit, OnDestroy {
    /**
     * Placeholder shown when no option(s) have been selected.
     */
    @Input()
    public placeholder = 'Select';

    /**
     * required - True if the dropdown is a required input.
     */
    @Input('required')
    private set setRequired(required: boolean | '') {
        this.required = required === '' || Boolean(required);
    }

    /**
     * Collection instance.
     */
    @Input()
    public collection: Collection;

    /**
     * If true, hides the search input.
     */
    @Input()
    public hideSearch = false;

    /**
     * True to disallow creating a new Item.
     */
    @Input()
    public disableCreate = false;

    /**
     * True to disallow editing a selected Item.
     */
    @Input()
    public disableEdit = false;

    /**
     * Params to be passed to collection.create.
     */
    @Input()
    public createParams = {};

    /**
     * True to allow multiple selection.
     */
    @Input()
    public multiple = false;

    /**
     * List of actions in the actions menu.
     */
    @Input()
    public actions: IAviDropdownButtonAction[];

    /**
     * Holds value of prepend for dropdown.
     */
    @Input()
    public prepend?: string;

    /**
     * Position of the actions menu tooltip.
     */
    @Input()
    public actionPosition = AviDropdownButtonPosition.TOP_RIGHT;

    /**
     * Flag to show a value as readonly. Differs from disabled in that the input field will be
     * displayed similarly to a label.
     */
    @Input()
    public readonly ?= false;

    /**
     * Optional Helper-text.
     * To be displayed below dropdown field.
     */
    @Input()
    public helperText ?= '';

    /**
     * To allow the access to dropdown action when the dropdown is disabled.
     */
    @Input()
    public allowActionsWhileDisabled = false;

    /**
     * Set through 'required' binding. Makes form field required.
     */
    public required = false;

    /**
     * Set through 'disabled' binding. Disables input and button.
     */
    public disabled = false;

    /**
     * Map of selected ref and its item instance.
     */
    public selectedItemsMap = new Map<DropdownModelValue, Item>();

    /**
     * Total number of loaded items.
     */
    private totalItems = 0;

    /**
     * Value being get/set as the ngModel value.
     */
    private modelValue: DropdownModelValue;

    /**
     * Default create action for the button menu.
     */
    private defaultCreateAction: IAviDropdownButtonAction = {
        label: 'Create',
        onClick: () => this.create(),
        disabled: () => this.disableCreate || !this.collection.isCreatable(),
    };

    /**
     * Default edit action for the button menu.
     */
    private defaultEditAction: IAviDropdownButtonAction = {
        label: 'Edit',
        onClick: () => {
            const selectedItem = this.selectedItemsMap.get(this.value);

            selectedItem.edit();
        },
        disabled: () => {
            const selectedItem = this.selectedItemsMap.get(this.value);

            return this.disableEdit || !selectedItem || !selectedItem.isEditable();
        },
        hidden: () => {
            const selectedItem = this.selectedItemsMap.get(this.value);

            return this.multiple || !selectedItem;
        },
    };

    constructor(private readonly stringService: StringService) {
        this.handleSearch = debounce(this.handleSearch, 500);
    }

    /** @override */
    public ngOnInit(): void {
        this.collection.updateViewportSize(PAGE_SIZE);
        this.collection.updateItemsVisibility(undefined, this.totalItems);
        this.setSelectedItemsMap();

        if (isUndefined(this.actions)) {
            this.actions = this.getDefaultActions();
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        this.resetSelectedItemsMap();
    }

    /**
     * Called when the options list has scrolled to the end. Loads new items.
     */
    public handleScrollEnd(totalItems: number): void {
        if (totalItems > this.totalItems) {
            this.collection.updateItemsVisibility(undefined, totalItems);
            this.totalItems = totalItems;
        }
    }

    /**
     * Called when an option has been selected. Sets the Item instance(s).
     */
    public handleSelect(): void {
        this.setSelectedItemsMap();
    }

    /**
     * Creates dropdown options from the collection's Items.
     */
    public get options(): IAviDropdownOption[] {
        return this.collection.itemList.map(getIDropdownItemOption);
    }

    /**
     * Called when the options menu has been opened or closed. When opened, loads the collection.
     */
    public handleOptionsOpenedChange(opened: boolean): void {
        if (opened) {
            this.totalItems = 0;
            this.collection.load(undefined, true);
        } else {
            this.collection.setSearch('');
        }
    }

    /**
     * Creates a new Item.
     */
    public async create(): Promise<void> {
        const createdItem = await this.collection.create(undefined, { ...this.createParams });
        const createdRef = createdItem.getRef();

        if (this.value instanceof Array) {
            this.value = [...this.value, createdRef];
        } else if (this.multiple) {
            this.value = [createdRef];
        } else {
            this.value = createdRef;
        }

        this.setSelectedItemsMap();
    }

    /**
     * Called to make an HTTP request search for Items.
     */
    public handleSearch = (searchTerm: string): void => {
        this.totalItems = 0;
        this.collection.search(searchTerm?.trim());
    };

    /**
     * Called to remove a selected value. Applicable for multiple-selection.
     */
    public handleRemoveValue(ref: DropdownModelValue): void {
        if (!(this.value instanceof Array)) {
            throw new Error('Can\'t remove value from a non-multiple-selection CollectionDropdown');
        }

        this.value = this.value.filter(value => value !== ref);
        this.setSelectedItemsMap();
    }

    /**
     * Getter for the modelValue.
     */
    public get value(): DropdownModelValue {
        return this.modelValue;
    }

    /**
     * Setter for the modelValue. If multiple is set to true, then the modelValue takes the form of
     * an array. Otherwise it's just a string.
     */
    public set value(val: DropdownModelValue) {
        if (this.modelValue !== val) {
            const normalizedValue = normalizeValue(val);

            this.modelValue = normalizedValue;
            this.onChange(normalizedValue);
        }

        this.onTouched();
    }

    /**
     * Returns true if every action is disabled.
     */
    public allActionsDisabled(): boolean {
        return every(this.actions, action => {
            if (typeof action.disabled !== 'function') {
                return false;
            }

            try {
                return action.disabled();
            } catch (error) {
                throw new Error(`action.disabled failed: ${error}`);
            }
        });
    }

    /***************************************************************************
     * IMPLEMENTING ControlValueAccessor INTERFACE
    */

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: (value: DropdownModelValue) => {}): void {
        this.onChange = fn;
    }

    /**
     * Writes the modelValue.
     */
    public writeValue(value: DropdownModelValue): void {
        this.modelValue = normalizeValue(value);
        this.setSelectedItemsMap();
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /**
     * Function that is called by the forms API when the control status changes to or from
     * 'DISABLED'. Depending on the status, it enables or disables the appropriate DOM element.
     */
    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /*************************************************************************/

    /**
     * Returns a set of default actions if none are passed in.
     */
    private getDefaultActions(): IAviDropdownButtonAction[] {
        const actions: IAviDropdownButtonAction[] = [];

        if (!this.disableCreate && this.collection.isCreatable()) {
            actions.push(this.defaultCreateAction);
        }

        if (!this.disableEdit && !this.multiple) {
            actions.push(this.defaultEditAction);
        }

        return actions;
    }

    /**
     * Sets selectedItemsMap based on ngModel value.
     */
    private setSelectedItemsMap(): void {
        this.resetSelectedItemsMap();

        const { value } = this;

        if (typeof value === 'string') {
            const selectedItem = this.getSelectedItem(value);

            this.selectedItemsMap.set(value, selectedItem);
        } else if (Array.isArray(value)) {
            value.forEach((ref: string) => {
                const selectedItem = this.getSelectedItem(ref);

                this.selectedItemsMap.set(ref, selectedItem);
            });
        }
    }

    /**
     * Destroys item instances & Clears selectedItems map.
     */
    private resetSelectedItemsMap(): void {
        if (this.selectedItemsMap.size) {
            for (const item of this.selectedItemsMap.values()) {
                item.destroy();
            }

            this.selectedItemsMap.clear();
        }
    }

    /**
     * Returns an Item instance based on a ref.
     */
    private getSelectedItem = (value: string): Item => {
        const selectedItemId = this.stringService.slug(value);
        const selectedItem = this.collection.getItemById(selectedItemId);

        // When setting modelValue on component initialization,
        // collection will not be loaded.
        // so itemList will be empty.
        let config = {};

        if (selectedItem instanceof ObjectTypeItem) {
            config = selectedItem.flattenConfig();
        } else if (selectedItem instanceof Item) {
            config = selectedItem.getConfig();
        }

        return this.collection.createNewItem({
            id: selectedItemId,
            data: {
                config: {
                    ...config,
                    url: value,
                },
            },
        }, true) as Item;
    };

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onChange = (value: DropdownModelValue): void => {};

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => {};
}
