/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'nat-policy';
const componentName = 'ip-addr-range-grid';
const prefix = `${moduleName}.${componentName}`;

export const addButtonLabel = `${prefix}.addButtonLabel`;
export const columnTitleIpAddress = `${prefix}.columnTitleIpAddress`;
export const columnTitleIpAddressRange = `${prefix}.columnTitleIpAddressRange`;
export const ipAddressPlaceholder = `${prefix}.ipAddressPlaceholder`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;
export const keyDuplicationErrorMsg = `${prefix}.keyDuplicationErrorMsg`;

export const ENGLISH = {
    [addButtonLabel]: 'Add',
    [columnTitleIpAddress]: 'IP Address',
    [columnTitleIpAddressRange]: 'IP Address Range',
    [ipAddressPlaceholder]: 'Enter IP Address',
    [removeButtonLabel]: 'Remove',
    [keyDuplicationErrorMsg]: 'System does not support duplicate values. Please update or delete.',
};
