/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'core';
const componentName = 'central-license-alerts';
const prefix = `${moduleName}.${componentName}`;

export const controllerNotRegisteredMessage = `${prefix}.controllerNotRegisteredMessage`;
export const registerControllerButtonText = `${prefix}.registerControllerButtonText`;
export const retryNowButtonText = `${prefix}.retryNowButtonText`;

export const ENGLISH = {
    [controllerNotRegisteredMessage]: 'Register your Controller with Cloud Services to complete product activation.',
    [registerControllerButtonText]: 'Register Controller',
    [retryNowButtonText]: 'Retry Now',
};
