/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ApplicationProfileModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import {
    ApplicationProfileType,
    AviPermissionResource,
    IApplicationProfile,
    IHTTPApplicationProfile,
    ProtocolType,
} from 'generated-types';

import { contains } from 'underscore';
import { L10nService } from '@vmw/ngx-vip';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';

import {
    ObjectTypeItem,
} from 'ajs/modules/data-model/factories/object-type-item.factory';

import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { ApplicationProfile as ApplicationProfileObjectType } from 'object-types';
import { DosRateLimitProfileConfigItem } from './dos-rate-limit-profile.config-item.factory';
import { HTTPApplicationProfileConfigItem } from './http-application-profile.config-item.factory';
import { RateLimiterProfileConfigItem } from './rate-limiter-profile.config-item.factory';

import {
    DnsServiceApplicationProfileConfigItem,
} from './dns-service-application-profile.config-item.factory';

import * as l10n from '../application-profile.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Short form of app profile type enum
 */
export enum ShortAppProfileType {
    SYSLOG = 'syslog',
    L4 = 'l4',
    HTTP = 'http',
    DNS = 'dns',
    SSL = 'ssl',
    SIP = 'sip',
}

type TApplicationProfilePartial = Omit<IApplicationProfile,
'dos_rl_profile' | 'dns_service_profile' | 'http_profile'>;

interface IApplicationProfileConfig extends TApplicationProfilePartial {
    dos_rl_profile: DosRateLimitProfileConfigItem;
    dns_service_profile: DnsServiceApplicationProfileConfigItem;
    http_profile: HTTPApplicationProfileConfigItem;
}

interface IApplicationProfileData {
    config: IApplicationProfileConfig;
}

/**
 * Application profile serverDefaultsOverride config.
 */
const SERVER_DEFAULTS_OVERRIDE = {
    dos_rl_profile: {
        rl_profile: {},
    },
};

/**
 * Hash for mapping config names to types in application profile.
 */
const CONFIG_NAME_TO_TYPE_HASH = {
    http_profile: ApplicationProfileType.APPLICATION_PROFILE_TYPE_HTTP,
    dns_service_profile: ApplicationProfileType.APPLICATION_PROFILE_TYPE_DNS,
    sip_service_profile: ApplicationProfileType.APPLICATION_PROFILE_TYPE_SIP,
    tcp_app_profile: [
        ApplicationProfileType.APPLICATION_PROFILE_TYPE_L4,
        ApplicationProfileType.APPLICATION_PROFILE_TYPE_SSL,
    ],
    dos_rl_profile: [
        ApplicationProfileType.APPLICATION_PROFILE_TYPE_DNS,
        ApplicationProfileType.APPLICATION_PROFILE_TYPE_L4,
        ApplicationProfileType.APPLICATION_PROFILE_TYPE_SSL,
        ApplicationProfileType.APPLICATION_PROFILE_TYPE_HTTP,
    ],
};

/**
 * @description Application Profile Item.
 *
 * @author Nisar Nadaf
 */
export class ApplicationProfile extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'l10nService',
        'defaultValues',
        'systemInfoService',
    ];

    /**
     * ApplicationProfile data.
     */
    public data: IApplicationProfileData;

    /**
     * l10nService instance to register source bundles and get keys from source bundles.
     */
    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'applicationprofile',
            objectType: ApplicationProfileObjectType,
            windowElement: 'lazy-load',
            permissionName: AviPermissionResource.PERMISSION_APPLICATIONPROFILE,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns URL/ref of the default network profile ref compatible with a passed appProfile
     * type.
     */
    public static getDefaultNetProfileRef(
        appProfileType: ApplicationProfileType | ShortAppProfileType,
    ): string {
        let nwProfName;
        const defaultValues = this.getAjsDependency_('defaultValues');

        switch (appProfileType) {
            case ApplicationProfileType.APPLICATION_PROFILE_TYPE_SYSLOG:
            case ShortAppProfileType.SYSLOG:
                nwProfName = 'System-UDP-No-SNAT';
                break;

            case ApplicationProfileType.APPLICATION_PROFILE_TYPE_DNS:
            case ShortAppProfileType.DNS:
                nwProfName = 'System-UDP-Per-Pkt';
                break;

            default:
                nwProfName = 'System-TCP-Proxy';
                break;
        }

        return defaultValues.getSystemObjectRefByName('networkprofile', nwProfName);
    }

    /**
     * Get HTTP Profile SSL everywhere enable value.
     *
     * ToDo - This method is being used by virtualService.js file.
     * At the time of refactoring of virtualservice item, we can remove this method
     * and use method from httpAppProfileConfigItem.
     * Non-static method is created in http-application-profile.config-item.factory.ts.
     */
    public static getHttpProfileSslEverywhereEnabledValue(
        httpProfile: IHTTPApplicationProfile | HTTPApplicationProfileConfigItem,
    ): boolean {
        let profile;

        if (httpProfile instanceof HTTPApplicationProfileConfigItem) {
            profile = httpProfile.config;
        } else {
            profile = httpProfile;
        }

        return profile.http_to_https &&
            profile.server_side_redirect_to_https &&
            profile.secure_cookie_enabled &&
            profile.hsts_enabled &&
            profile.httponly_enabled &&
            profile.x_forwarded_proto_enabled || false;
    }

    /**
     * Returns default VS service port by application profile type.
     */
    public static getDefaultServicePort(
        appProfileType: ApplicationProfileType | ShortAppProfileType,
    ): number {
        switch (appProfileType) {
            case ApplicationProfileType.APPLICATION_PROFILE_TYPE_SYSLOG:
            case ShortAppProfileType.SYSLOG:
                return 514;

            case ApplicationProfileType.APPLICATION_PROFILE_TYPE_DNS:
            case ShortAppProfileType.DNS:
                return 53;

            case ApplicationProfileType.APPLICATION_PROFILE_TYPE_SSL:
            case ShortAppProfileType.SSL:
                return 443;

            case ApplicationProfileType.APPLICATION_PROFILE_TYPE_SIP:
            case ShortAppProfileType.SIP:
                return 5060;

            case ShortAppProfileType.HTTP:
            case ApplicationProfileType.APPLICATION_PROFILE_TYPE_HTTP:
            case ShortAppProfileType.L4:
            case ApplicationProfileType.APPLICATION_PROFILE_TYPE_L4:
            default:
                return 80;
        }
    }

    /**
     * Return compatible network profile types for provided application profile type.
     */
    public static getAllowedNetProfileTypes(
        appProfileType: ApplicationProfileType | ShortAppProfileType,
        types: ProtocolType[],
    ): ProtocolType[] {
        const list: ProtocolType[] = [];

        switch (appProfileType) {
            case ApplicationProfileType.APPLICATION_PROFILE_TYPE_HTTP:
            case ShortAppProfileType.HTTP:
            case ApplicationProfileType.APPLICATION_PROFILE_TYPE_SSL:
            case ShortAppProfileType.SSL:
                list.push(ProtocolType.PROTOCOL_TYPE_TCP_PROXY);
                break;

            case ApplicationProfileType.APPLICATION_PROFILE_TYPE_SYSLOG:
            case ShortAppProfileType.SYSLOG:
                list.push(ProtocolType.PROTOCOL_TYPE_UDP_FAST_PATH);
                break;

            // All types are allowed for DNS and L4.
            default:
                list.push(...types);
        }

        return list;
    }

    /**
     * Import lazy-loaded modal component.
     */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { ApplicationProfileModalComponent } = await import(
            /* webpackChunkName: "application-profile-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/application-profile-modal/application-profile-modal.component'
        );

        return ApplicationProfileModalComponent as Type<Component>;
    }

    /**
     * Event handler for type change event.
     */
    public onTypeChange(): void {
        const appProfileType = this.getType();

        Object.keys(CONFIG_NAME_TO_TYPE_HASH).forEach(configName => {
            const fieldName = CONFIG_NAME_TO_TYPE_HASH[configName];

            const deleteField =
                Array.isArray(fieldName) ?
                    !contains(fieldName, appProfileType) : appProfileType !== fieldName;

            if (deleteField) {
                delete this.config[configName];
            }
        });

        switch (appProfileType) {
            case ApplicationProfileType.APPLICATION_PROFILE_TYPE_L4:
            case ApplicationProfileType.APPLICATION_PROFILE_TYPE_SSL:

                this.safeSetNewChildByField('tcp_app_profile');

                // To handle edit scenario when config dont have dos_rl_profile object.
                // reset for New creation or in case of edit but dos_rl_profile not present.
                if (!this.id || this.id && !this.config.dos_rl_profile) {
                    this.resetRlProfile();
                }

                break;

            case ApplicationProfileType.APPLICATION_PROFILE_TYPE_SIP:
                this.safeSetNewChildByField('sip_service_profile');

                break;

            case ApplicationProfileType.APPLICATION_PROFILE_TYPE_HTTP:
                this.safeSetNewChildByField('http_profile');

                if (!this.id || this.id && !this.config.dos_rl_profile) {
                    this.resetRlProfile();
                }

                break;
            case ApplicationProfileType.APPLICATION_PROFILE_TYPE_DNS:
                this.safeSetNewChildByField('dns_service_profile');

                // To handle edit scenario when config dont have dos_rl_profile object.
                // reset for New creation or in case of edit but dos_rl_profile not present.
                if (!this.id || this.id && !this.config.dos_rl_profile) {
                    this.resetRlProfile();
                }

                break;
        }
    }

    /**
     * @override
     */
    public beforeEdit(): void {
        const config = this.getConfig();

        const { markers } = config;

        if (!markers) {
            config.markers = [];
        }

        // Reset config items based on selected type.
        this.onTypeChange();
    }

    /**
     * @override
     */
    public dataToSave(): IApplicationProfile {
        const config = super.dataToSave();

        const { markers } = config;

        if (markers) {
            // filter out RBAC entries with an empty key
            config.markers = markers.filter((key: string) => key);

            // delete empty RABC label list
            if (markers.length === 0) {
                delete config.markers;
            }
        }

        return config;
    }

    /**
     * Returns Type of application profile.
     */
    public getType(): ApplicationProfileType {
        const { type } = this.getConfig();

        return type;
    }

    /**
     * Getter for Rate Limiter Profile used for dos rate limiter section.
     */
    public get rateLimitProfile(): RateLimiterProfileConfigItem {
        const { dos_rl_profile: { rlProfile } } = this.config;

        return rlProfile;
    }

    /**
     * Getter for Http Profile config.
     */
    public get httpProfile(): HTTPApplicationProfileConfigItem {
        const { http_profile: httpProfile } = this.config;

        return httpProfile;
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.applicationProfileBreadcrumbTitle);
    }

    /**
     * Create dos_rl_profile object and override default values.
     */
    private resetRlProfile(): void {
        const config = this.getConfig();

        this.safeSetNewChildByField('dos_rl_profile');
        config.dos_rl_profile.updateConfig(SERVER_DEFAULTS_OVERRIDE.dos_rl_profile);
    }
}
