/**
 * Module for NAT Policy related items and components
 * @module NatModule
 * @preferred
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import {
    NatPolicyModalComponent,
} from 'ng/modules/nat-policy/components/nat-policy-modal/nat-policy-modal.component';

import { NatPolicy } from 'object-types';
import { AviPermissionResource } from 'generated-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { NatRuleConfigItem } from '.';
import * as l10n from '../../policies.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const NAT_RULE = 'rules';

interface INatPolicyConfig {
    rules: RepeatedMessageItem<NatRuleConfigItem>;
}

export class NatPolicyItem extends
    withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public static ajsDependencies = [
        'l10nService',
    ];

    // overriding getConfig()'s return type
    public getConfig: () => INatPolicyConfig;

    private readonly l10nService: L10nService;

    constructor(args: {}) {
        const extendedArgs = {
            objectName: 'natpolicy',
            objectType: NatPolicy,
            permissionName: AviPermissionResource.PERMISSION_NATPOLICY,
            windowElement: NatPolicyModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Method to add Nat rule item.
     */
    public addNatRule(): void {
        this.addChildMessageItem({
            field: NAT_RULE,
        });
    }

    /**
     * Method to edit Syslog server server item.
     */
    public editSyslogServer(natRule: NatRuleConfigItem): void {
        this.addChildMessageItem({
            field: NAT_RULE,
            messageItem: natRule,
        });
    }

    /** @override */
    public getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.natRuleBreadcrumbTitle);
    }
}
