/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'nat-policy';
const componentName = 'nat-rule-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalHeader = `${prefix}.modalHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const generalLabel = `${prefix}.generalLabel`;
export const enableRuleLabel = `${prefix}.enableRuleLabel`;
export const matchSectionTitle = `${prefix}.matchSectionTitle`;
export const serviceMatchLabel = `${prefix}.serviceMatchLabel`;
export const actionSectionTitle = `${prefix}.actionSectionTitle`;
export const typeInputLabel = `${prefix}.typeInputLabel`;
export const typeSelectPlaceholder = `${prefix}.typeSelectPlaceholder`;
export const ipAddressLabel = `${prefix}.ipAddressLabel`;
export const sourceAddressLabel = `${prefix}.sourceAddressLabel`;
export const destinationAddressLabel = `${prefix}.destinationAddressLabel`;

export const ENGLISH = {
    [modalHeader]: 'Nat Rule',
    [nameInputLabel]: 'Name',
    [generalLabel]: 'General',
    [enableRuleLabel]: 'Enable Rule',
    [matchSectionTitle]: 'Match',
    [serviceMatchLabel]: 'Services',
    [actionSectionTitle]: 'Action',
    [typeInputLabel]: 'Type',
    [typeSelectPlaceholder]: 'Select a Type',
    [ipAddressLabel]: 'IP Address',
    [sourceAddressLabel]: 'Source IP Address',
    [destinationAddressLabel]: 'Destination IP Address',
};
