/**
 * @module NatModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { each } from 'underscore';
import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    Type,
} from '@angular/core';

import {
    IMatchOption,
    IpAddrMatchComponent,
    ServiceMatchComponent,
} from 'ng/modules/match/components';

import {
    IIpAddr,
    IIpAddrRange,
} from 'generated-types';

import { NatMatchTarget } from 'object-types';
import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { createOptionsFromEnumProps } from 'ng/shared/utils/dropdown.utils';
import {
    INatActionIpAddrRange,
    NatRuleConfigItem,
} from 'ajs/modules/policies/factories/nat-policy/nat-rule.config-item.factory';

import * as l10n from './nat-rule-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 * NatRuleModalComponent modal component.
 *
 * @author Guru Prasad
 */
@Component({
    selector: 'nat-rule-modal',
    templateUrl: './nat-rule-modal.component.html',
})
export class NatRuleModalComponent implements OnInit {
    /**
     * NatRuleConfigItem instance.
     */
    @Input()
    public editable: NatRuleConfigItem;

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit = new EventEmitter<void>();

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * NatRule object type.
     */
    public objectType: string;

    /**
     * List of all possible matches to be configured for the rule.
     */
    public matchOptions: IMatchOption[];

    /**
     * Type dropdown options.
     */
    public natPolicyActionTypeOptions: IAviDropdownOption[] = [];

    /**
     * Layout for NatRule modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Stores all IP addresses in modal's grid in single array format.
     */
    public ipAddresses: INatActionIpAddrRange[];

    constructor(
        schemaService: SchemaService,
        private readonly l10nService: L10nService,
        @Inject('RangeParser')
        private rangeParser: any,
    ) {
        l10nService.registerSourceBundles(dictionary);

        const natPolicyActionTypes = schemaService.getEnumValues('NatPolicyActionType');

        this.natPolicyActionTypeOptions = createOptionsFromEnumProps(natPolicyActionTypes);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
        this.setMatchOptions();
        this.ipAddresses = this.editable.addresses;
    }

    /**
     * Fires on submit.
     */
    public submit(): void {
        this.updateConfig();
        this.onSubmit.emit();
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }

    /**
     * Called to add an address entry in grid.
     */
    public addIpAddr(): void {
        this.ipAddresses.push({
            addr: undefined,
            range: undefined,
        });
    }

    /**
     * Called to remove an address entry in grid.
     */
    public onRemoveIps(ipList: any): void {
        ipList.forEach((item: any) => {
            const index = this.ipAddresses.findIndex((ipAddr: any) => {
                // if clear button clicked then value come as undefined.
                if (!item.addr) {
                    return !ipAddr.addr;
                }

                return ipAddr.addr.join(',') === item.addr.join(',');
            });

            this.ipAddresses.splice(index, 1);
        });
    }

    /**
     * Updates config by removing old IP addresses and popultaing
     * with updated IP addrs and range values
     */
    public updateConfig(): void {
        const natIp = 'nat_ip';
        const natIpRange = 'nat_ip_range';

        this.editable.removeAllIpAddresses();

        each(this.ipAddresses, (server: INatActionIpAddrRange) => {
            if (!server.addr) {
                return;
            }

            const natAction = {};

            if (server.addr) {
                const rangeOrIp = this.rangeParser.ipRange2Json(server.addr.trim());
                const ipAddr: IIpAddr = rangeOrIp;

                natAction[natIp] = ipAddr;
            }

            if (server.range) {
                const range = this.rangeParser.ipRange2Json(server.range.trim());
                const ipRange: IIpAddrRange = range;

                natAction[natIpRange] = ipRange;
            }

            this.editable.config.action.config.nat_info.add(natAction);
        });
    }

    /**
     * Set match options for use by match-adder.
     */
    private setMatchOptions(): void {
        this.matchOptions = [
            {
                component: IpAddrMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.destinationAddressLabel),
                objectType: NatMatchTarget,
                fieldName: 'destination_ip',
            },
            {
                component: IpAddrMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.sourceAddressLabel),
                objectType: NatMatchTarget,
                fieldName: 'source_ip',
            },
            {
                component: ServiceMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.serviceMatchLabel),
                objectType: NatMatchTarget,
                fieldName: 'services',
            },
        ];
    }
}
