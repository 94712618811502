/**
 * @module NatModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */
const moduleName = 'nat-policy';
const componentName = 'nat-policy-modal';
const prefix = `${moduleName}.${componentName}`;

export const nameLabel = `${prefix}.nameLabel`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const descriptionInputLabel = `${prefix}.descriptionInputLabel`;
export const descriptionInputPlaceholder = `${prefix}.descriptionInputPlaceholder`;
export const editNatPolicyModalHeader = `${prefix}.editNatPolicyModalHeader`;
export const newNatPolicyModalHeader = `${prefix}.newNatPolicyModalHeader`;
export const editLabel = `${prefix}.editLabel`;
export const deleteLabel = `${prefix}.deleteLabel`;
export const addRule = `${prefix}.addRule`;
export const rulesTitle = `${prefix}.rulesTitle`;
export const ruleStatus = `${prefix}.ruleStatus`;

export const ENGLISH = {
    [nameLabel]: 'Name',
    [sectionTitleGeneral]: 'General',
    [descriptionInputLabel]: 'Description',
    [descriptionInputPlaceholder]: 'Enter Description',
    [editNatPolicyModalHeader]: 'Edit NAT Policy: {0}',
    [newNatPolicyModalHeader]: 'New NAT Policy: {0}',
    [editLabel]: 'Edit',
    [deleteLabel]: 'Delete',
    [addRule]: 'Add Rule',
    [rulesTitle]: 'Rules',
    [ruleStatus]: 'Rule Status',
};
