/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { MatchModule } from 'ng/modules/match/match.module';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';
import { GroupsModule } from '../groups/groups.module';
import {
    natPolicyCollectionProvider,
} from './ajs-upgraded-providers';

import {
    NatActionIpAddrRangeComponent,
    NatPolicyListPageComponent,
    NatPolicyModalComponent,
    NatRuleModalComponent,
} from './components';

const natPolicyComponents = [
    NatActionIpAddrRangeComponent,
    NatPolicyListPageComponent,
    NatPolicyModalComponent,
    NatRuleModalComponent,
];

@NgModule({
    declarations: [
        ...natPolicyComponents,
    ],
    imports: [
        CommonModule,
        DragDropModule,
        SharedModule,
        AviFormsModule,
        MatchModule,
        GroupsModule,
        FormsModule,
        BrowserAnimationsModule,
        DataGridModule,
    ],
    providers: [
        natPolicyCollectionProvider,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class NatModule {}
