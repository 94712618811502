/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SecurityModule
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    TemplateRef,
    Type,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    IAviCollectionDataGridConfig,
    IAviCollectionDataGridConfigField,
    IAviCollectionDataGridRow,
    IAviCollectionDataGridSingleaction,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import {
    SSLCertificateStatus,
    SSLCertificateType,
    SSLKeyAlgorithm,
} from 'generated-types';

import {
    AviDataGridFieldVisibility,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import {
    CertificateCollection,
} from 'ajs/modules/security/factories/certificates/certificate.collection.factory';

import {
    Certificate,
} from 'ajs/modules/security/factories/certificates/certificate.item.factory';

import {
    SslCertificateExportModalService,
} from 'ng/modules/security/services/ssl-certificate-export-modal.service';

import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { StringService } from 'ajs/modules/core/services/string-service/string.service';
import { DialogService } from 'ng/modules/core/services/dialog.service';

import {
    HttpMethod,
    HttpWrapper,
    THttpWrapper,
} from 'ajs/modules/core/factories/http-wrapper/http-wrapper.service';

import {
    AviContinueConfirmationComponent,
} from 'ng/modules/dialog/components/avi-continue-confirmation/avi-continue-confirmation.component';

import { CERTIFICATE_COLLECTION_TOKEN } from 'ajs/modules/security/security.tokens';
import * as globalL10n from 'global-l10n';
import * as l10n from './ssl-certificate-list.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TCertificateCollection = typeof CertificateCollection;

const RENEW_CERTIFICATE_DIALOG_ID = 'renew-certificate-confirmation';
const BASE_URL = '/api/sslkeyandcertificate/';
const RENEW_CERT_ENDPOINT = '/renew';

/**
 * @description SSL Certificate List.
 *
 * @author Manideep Kotha, Harmeet Kaur
 */

@Component({
    selector: 'ssl-profile-list-page',
    templateUrl: './ssl-certificate-list.component.html',
})
export class SslCertificateListComponent implements OnInit, OnDestroy {
    /**
     * Datagrid template for input field of the name in the label pair.
     */
    @ViewChild('nameFieldTemplateRef')
    public nameFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for input field of the status in the label pair.
     */
    @ViewChild('statusFieldTemplateRef')
    public statusFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * SslCertificate expander template.
     */
    @ViewChild('expandedContainerTemplateRef')
    public expandedContainerTemplateRef: TemplateRef<HTMLElement>;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Configuration object to display SSL Certificate Collection.
     */
    public sslCertificateListGridConfig: IAviCollectionDataGridConfig;

    /**
     * Configuration object to display SSL Auth Certificate collection.
     */
    public sslAuthCertificateGridConfig: IAviCollectionDataGridConfig;

    /**
     * Errors to be displayed if an error occurs.
     */
    public errors: string | null;

    /**
     * Message to be displayed if renew is successful.
     */
    public successAlert: string | null;

    private sslCertificateCollection: CertificateCollection;

    private sslAuthCertificateCollection: CertificateCollection;

    private readonly httpWrapper: HttpWrapper;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(CERTIFICATE_COLLECTION_TOKEN)
        private readonly SSLCertificateCollection: TCertificateCollection,
        private readonly sslCertificateExportModalService: SslCertificateExportModalService,
        private readonly schemaService: SchemaService,
        private readonly stringService: StringService,
        private readonly dialogService: DialogService,
        @Inject(HttpWrapper)
        HttpWrapper: THttpWrapper,
    ) {
        l10nService.registerSourceBundles(dictionary);

        const certCollectionEnums =
            this.schemaService.getEnumKeys('SSLCertificateType')
                .filter(type => type !== SSLCertificateType.SSL_CERTIFICATE_TYPE_CA).join();

        this.sslCertificateCollection = new SSLCertificateCollection({
            params: {
                type: certCollectionEnums,
            },
        });
        this.sslAuthCertificateCollection = new SSLCertificateCollection({
            params: {
                type: SSLCertificateType.SSL_CERTIFICATE_TYPE_CA,
            },
        });
        this.httpWrapper = new HttpWrapper();
    }

    /** @override */
    public ngOnInit(): void {
        this.createSslCertificateListGridConfig();
        this.createSslAuthCertificateGridConfig();
    }

    /**
     * @override
     *
     * Template Refs must be set after the view is initialized.
     */
    public ngAfterViewInit(): void {
        this.sslCertificateListGridConfig = this.updateGrid(this.sslCertificateListGridConfig);
        this.sslAuthCertificateGridConfig = this.updateGrid(this.sslAuthCertificateGridConfig);
    }

    /** @override */
    public ngOnDestroy(): void {
        this.sslCertificateCollection.destroy();
        this.sslAuthCertificateCollection.destroy();
    }

    /**
     * Called to check if expander required to be rendered.
     */
    private disableExpander(certificate: Certificate): boolean {
        return !(certificate.isOcspApplicable && certificate.enableOcspStapling);
    }

    /**
     * Return singleactions for the gridConfig.
     */
    private getSingleActions(): IAviCollectionDataGridSingleaction[] {
        return [
            {
                label: this.l10nService.getMessage(l10nKeys.actionBtnExport),
                id: 'download',
                shape: 'download',
                hidden: (row: IAviCollectionDataGridRow) =>
                    row.data.config.status === SSLCertificateStatus.SSL_CERTIFICATE_PENDING,
                onClick: (row: IAviCollectionDataGridRow) => {
                    this.sslCertificateExportModalService
                        .openModal(row.id, row.getName());
                },
            },
            {
                label: this.l10nService.getMessage(l10nKeys.actionBtnRenew),
                id: 'renew-cert',
                shape: 'renew-certificate',
                onClick: (row: Certificate) => this.renewCertificateConfirmation(row),
            },
            {
                label: this.l10nService.getMessage(globalL10nKeys.editLabel),
                id: 'edit',
                shape: 'pencil',
                hidden: (row: IAviCollectionDataGridRow) => !row.isEditable(),
                onClick: (row: IAviCollectionDataGridRow) => row.edit(),
            },
        ];
    }

    /**
     * Return grid fields for grid config.
     */
    private getFields(): IAviCollectionDataGridConfigField[] {
        return [
            {
                id: 'name',
                label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                templateRef: this.nameFieldTemplateRef,
                sortBy: 'name',
            },
            {
                id: 'status',
                label: this.l10nService.getMessage(globalL10nKeys.statusLabel),
                templateRef: this.statusFieldTemplateRef,
            },
            {
                id: 'common',
                label: this.l10nService.getMessage(l10nKeys.columnTitleCommonName),
                transform: (row: IAviCollectionDataGridRow): string =>
                    row.data.config.certificate?.config.subject.config?.common_name,
                visibility: AviDataGridFieldVisibility.MANDATORY,
            },
            {
                id: 'issuer',
                label: this.l10nService.getMessage(l10nKeys.columnTitleIssuerName),
                transform: (row: IAviCollectionDataGridRow): string =>
                    row.data.config.certificate?.config.issuer.config?.common_name,
                visibility: AviDataGridFieldVisibility.MANDATORY,
            },
            {
                id: 'algorithm',
                label: this.l10nService.getMessage(l10nKeys.columnTitleAlgorithm),
                transform: (row: IAviCollectionDataGridRow): string => {
                    const keyParams = row.getConfig()?.key_params;
                    const { algorithm } = keyParams ?? {};
                    let algo = '';

                    if (algorithm) {
                        algo = algorithm.replace('SSL_KEY_ALGORITHM_', '');

                        switch (algorithm) {
                            case SSLKeyAlgorithm.SSL_KEY_ALGORITHM_RSA: {
                                const keySize = this.stringService.enumeration(
                                    keyParams.keySize,
                                    'SSL_KEY_',
                                );

                                return `${algo}(${keySize})`;
                            }

                            case SSLKeyAlgorithm.SSL_KEY_ALGORITHM_EC: {
                                const { curve } = keyParams;

                                return `${algo}(${curve.replace('SSL_KEY_EC_CURVE_', '')})`;
                            }
                        }
                    }

                    return null;
                },
            },
            {
                id: 'selfsigned',
                label: this.l10nService.getMessage(l10nKeys.columnTitleSelfSigned),
                transform: (row: Certificate): string => {
                    const certificateSelfSignedLabel = row.isSelfSigned ?
                        globalL10nKeys.yesValueLabel : globalL10nKeys.noValueLabel;

                    return this.l10nService.getMessage(certificateSelfSignedLabel);
                },
                visibility: AviDataGridFieldVisibility.MANDATORY,
            },
            {
                id: 'valid',
                label: this.l10nService.getMessage(l10nKeys.columnTitleValidUntil),
                transform: (row: IAviCollectionDataGridRow): string => {
                    const { certificate, status } = row.getConfig();
                    const { config } = certificate;
                    const { not_after: notAfter } = config;

                    const certificateValidLabel =
                        status !== SSLCertificateStatus.SSL_CERTIFICATE_PENDING ?
                            notAfter :
                            this.l10nService.getMessage(l10nKeys.awaitingCertificateValue);

                    return certificateValidLabel;
                },
            },
            {
                id: 'san_domain_names',
                label: this.l10nService.getMessage(l10nKeys.columnTitleSanDomainNames),
                transform: (row: IAviCollectionDataGridRow): string =>
                    row.data.config.certificate?.config.subject_alt_names?.join(', '),
                visibility: AviDataGridFieldVisibility.OPTIONAL,
            },
        ];
    }

    /**
     * Return the updated grid with expanded content template.
     */
    private updateGrid(gridConfig: IAviCollectionDataGridConfig): IAviCollectionDataGridConfig {
        gridConfig = {
            ...gridConfig,
            fields: this.getFields(),
            expandedContentTemplateRef: this.expandedContainerTemplateRef,
            expanderDisabled: (certificate: Certificate) => this.disableExpander(certificate),
        };

        return gridConfig;
    }

    /**
     * Create ssl certificate list grid config.
     */
    private createSslCertificateListGridConfig(): void {
        const { objectName: sslCertObject } = this.sslCertificateCollection;

        this.sslCertificateListGridConfig = {
            id: `${sslCertObject}-list-page`,
            collection: this.sslCertificateCollection,
            fields: [],
            singleactions: this.getSingleActions(),
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
                hideDelete: true,
                hideEdit: true,
            },
            createActions: [{
                label: this.l10nService.getMessage(l10nKeys.btnLabelRootIntermediateCaCert),
                onClick: () => {
                    this.sslAuthCertificateGridConfig.collection.setDefaultItemConfigProps({
                        type: SSLCertificateType.SSL_CERTIFICATE_TYPE_CA,
                    });

                    this.sslAuthCertificateGridConfig.collection.create();
                },
            }, {
                label: this.l10nService.getMessage(l10nKeys.btnLabelApplicationCert),
                onClick: () => {
                    this.sslCertificateListGridConfig.collection.setDefaultItemConfigProps({
                        type: SSLCertificateType.SSL_CERTIFICATE_TYPE_VIRTUALSERVICE,
                    });

                    this.sslCertificateListGridConfig.collection.create();
                },
            }, {
                label: this.l10nService.getMessage(l10nKeys.btnLabelControllerCert),
                onClick: () => {
                    this.sslCertificateListGridConfig.collection.setDefaultItemConfigProps({
                        type: SSLCertificateType.SSL_CERTIFICATE_TYPE_SYSTEM,
                    });

                    this.sslCertificateListGridConfig.collection.create();
                },
            }],
            getRowId: (index: number, row: IAviCollectionDataGridRow): string => row.id,
        };
    }

    /**
     * Create ssl auth certificate grid config.
     */
    private createSslAuthCertificateGridConfig(): void {
        const { objectName: sslAuthCertificateObject } = this.sslAuthCertificateCollection;

        this.sslAuthCertificateGridConfig = {
            id: `${sslAuthCertificateObject}-list-page`,
            collection: this.sslAuthCertificateCollection,
            fields: [],
            singleactions: this.getSingleActions(),
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
                hideDelete: true,
                hideEdit: true,
                hideCreate: true,
            },
            getRowId: (index: number, row: IAviCollectionDataGridRow): string => row.id,
        };
    }

    /**
     * Display renew certificate Confirmation dialog.
     */
    private renewCertificateConfirmation(row: Certificate): void {
        const { type } = row.config;
        const certTypeKey = type === SSLCertificateType.SSL_CERTIFICATE_TYPE_CA ?
            l10nKeys.btnLabelRootIntermediateCaCert : l10nKeys.sslTlsCertificatesHeader;
        const certTypeLabel = this.l10nService.getMessage(certTypeKey);

        this.dialogService.add({
            id: RENEW_CERTIFICATE_DIALOG_ID,
            component: AviContinueConfirmationComponent as Type<Component>,
            componentProps: {
                warning: this.l10nService.getMessage(
                    l10nKeys.renewCertificateConfirmMessage,
                    [row.getName()],
                ),
                customHeader: this.l10nService.getMessage(
                    l10nKeys.renewCertificateHeader,
                    [certTypeLabel],
                ),
                onConfirm: () => {
                    this.dialogService.remove(RENEW_CERTIFICATE_DIALOG_ID);
                    this.renewSelectedCertificate(row);
                },
                onClose: () => {
                    this.dialogService.remove(RENEW_CERTIFICATE_DIALOG_ID);
                },
            },
        });
    }

    /**
     * Renew certificate and display result.
     */
    private async renewSelectedCertificate(row: Certificate): Promise<void> {
        const {
            name,
            type,
            uuid,
        } = row.config;
        const collection = type === SSLCertificateType.SSL_CERTIFICATE_TYPE_CA ?
            this.sslAuthCertificateCollection : this.sslCertificateCollection;

        this.errors = null;
        this.successAlert = null;
        collection.busy = true;

        const requestConfig = {
            method: HttpMethod.POST,
            url: `${BASE_URL}${uuid}${RENEW_CERT_ENDPOINT}`,
        };

        try {
            await this.httpWrapper.request(requestConfig);
            this.successAlert = this.l10nService.getMessage(
                l10nKeys.renewalSuccessMessage,
                [name],
            );
        } catch ({ data }) {
            this.errors = data.error;
        } finally {
            collection.busy = false;
        }
    }
}
