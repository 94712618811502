/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ServiceEngineGroup */

import {
    Component,
    Type,
} from '@angular/core';

import {
    IHttpResponse,
    IPromise,
} from 'angular';

import {
    isEmpty,
    isUndefined,
} from 'underscore';

import {
    AviPermissionResource,
    Icc_securitygroup_rsp,
    Icc_zone_rsp,
    ICloud,
    ICloudAZ,
    ICloudSecurityGroup,
    ICustomTag,
    IDatastoreInfo,
    IMetricsRealTimeUpdate,
    IpAddrType,
    IServiceEngineGroup,
    IStaticRoute,
    IUpgradeStatusInfo,
    IUserAgentCacheConfig,
    IVcenterDatastore,
    IVIDatastore,
    IVrfContext,
    PlacementAlgorithm,
    SEBandwidthType,
    VcenterDatastoreMode,
} from 'generated-types';

import {
    IUpgradeStatusEssential,
    withUpgradeStatusMixin,
} from 'ajs/modules/upgrade';

import {
    SubnetListNetworkCollection,
} from 'ajs/modules/network/factories/subnet-list-network.collection.factory';

import { SubnetListNetwork } from 'ajs/modules/network/factories/subnet-list-network.item.factory';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { ServiceEngineGroup } from 'object-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import { PlacementScopeConfigConfigItem } from './placement-scope-config.config-item.factory';
import { VipAutoscaleGroupConfigItem } from './vip-autoscale-group.config-item.factory';
import { VcenterClustersConfigItem } from './vcenter-clusters.config-item.factory';
import { VcenterHostsConfigItem } from './vcenter-hosts.config-item.factory';

const { ...globalL10nKeys } = globalL10n;

type TChildMessageFields = 'realtime_se_metrics' |
'vcenter_clusters' |
'vcenter_hosts' |
'vcenters' |
'vip_asg' |
'custom_tag' |
'user_agent_cache_config';

type TIServiceEngineGroupPartial = Omit<IServiceEngineGroup, TChildMessageFields>;

interface IServiceEngineGroupConfig extends TIServiceEngineGroupPartial {
    realtime_se_metrics?: MessageItem<IMetricsRealTimeUpdate>;
    vcenter_clusters?: VcenterClustersConfigItem;
    vcenter_hosts?: VcenterHostsConfigItem;
    vcenters?: RepeatedMessageItem<PlacementScopeConfigConfigItem>;
    vip_asg?: VipAutoscaleGroupConfigItem;
    custom_tag?: RepeatedMessageItem<MessageItem<ICustomTag>>;
    user_agent_cache_config?: MessageItem<IUserAgentCacheConfig>;
}

interface ISegInventoryData {
    serviceengines: string[];
    virtualservices: string[];
    config: IServiceEngineGroupConfig;
    upgradestatus: IUpgradeStatusInfo;
}

interface IServiceEngineGroupConfigData {
    config: IServiceEngineGroupConfig;
    inventory: ISegInventoryData;
}

/**
 * Constants used in the class.
 */
const DEFAULT_SE_GROUP_NAME = 'Default-Group';
const VIMGR_VCENTER_RUNTIME_DATASTORES_URL = '/api/vimgrvcenterruntime/datastores';
const VRF_CONTEXT_MGMT_URL = '/api/vrfcontext?name=management';

/**
 * @desc
 *
 *      Intermediate SEGroupItem class. This internal class is not registered to ajs.
 *      Integration of the SEGroupItem class and UpgradeStatusMixin. This is registered to ajs.
 *
 * @author Zhiqian Liu, vgohil
 */
export class SEGroupItem extends withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem))
    implements IUpgradeStatusEssential {
    public static ajsDependencies = [
        'l10nService',
        'naturalSort',
        'getSubnetObject',
        'SubnetListNetwork',
        'SubnetListNetworkCollection',
    ];

    /**
     * SE Group item data.
     */
    public data: IServiceEngineGroupConfigData;

    /**
     * Definition of getConfig method.
     */
    public getConfig: () => IServiceEngineGroupConfig;

    /**
     * Management VRF Context object.
     */
    public vrfContext: IVrfContext;

    /**
     * Static route object for this SE Group.
     */
    public staticRoute: IStaticRoute;

    /**
     * Ref. to SubnetListNetwork item class.
     */
    private readonly SubnetListNetwork: typeof SubnetListNetwork;

    /**
     * Ref. to SubnetListNetworkCollection collection class.
     */
    private readonly SubnetListNetworkCollection: typeof SubnetListNetworkCollection;

    /**
     * L10nService instance to register source bundles and get keys from source bundles.
     */
    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'serviceenginegroup',
            windowElement: 'lazy-load',
            objectType: ServiceEngineGroup,
            permissionName: AviPermissionResource.PERMISSION_SERVICEENGINEGROUP,
            whitelistedFields: [
                'realtime_se_metrics',
                'vcenter_clusters',
                'vcenter_hosts',
                'vcenters',
                'vip_asg',
                'custom_tag',
                'user_agent_cache_config',
            ],
            restrictEditOnEssentialLicense: false,
            ...args,
        };

        super(extendedArgs);

        this.SubnetListNetwork = this.getAjsDependency_('SubnetListNetwork');
        this.SubnetListNetworkCollection = this.getAjsDependency_('SubnetListNetworkCollection');

        this.vrfContext = null;
        this.staticRoute = null;

        this.l10nService = this.getAjsDependency_('l10nService');
    }

    /**
     * Getter for number of virtual services attached to this SEGroup.
     */
    public get numOfVs(): number {
        return this.getInventoryData().virtualservices.length;
    }

    /**
     * Getter for number of service engines under this SEGroup.
     */
    public get numOfSe(): number {
        return this.getInventoryData().serviceengines.length;
    }

    /**
     * Method used to import lazy loaded modal component.
     */
    /* eslint-disable-next-line */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { SEGroupModalComponent } = await import(
            /* webpackChunkName: "se-group-modal" */
            'ng/lazy-loaded-components/modals/se-group-modal/se-group-modal.component'
        );

        return SEGroupModalComponent as Type<Component>;
    }

    /**
     * Getter for HA mode of this SEGroup.
     */
    public get haMode(): string {
        return this.config.ha_mode;
    }

    /**
     * SEGroup#id for default groups of non default clouds is not returned by `default-values`
     * API. Hence we need to make a simple name check here as used by the backend. Within
     * edit windows method MUST be used with one time binding, cause otherwise if customer
     * provides such name this method will return true for that editable.
     * @override
     */
    public isProtected(): boolean {
        return this.getName() === DEFAULT_SE_GROUP_NAME || super.isProtected();
    }

    /**
     * Get the full inventory data object.
     */
    public getInventoryData(): ISegInventoryData {
        return this.data.inventory;
    }

    /**
     * Returns the se_bandwidth_type of SEGroup.
     */
    public get seBandwidthType(): SEBandwidthType {
        return this.config.se_bandwidth_type;
    }

    /**
     * Sets the se_bandwidth_type of SEGroup.
     */
    public set seBandwidthType(value: SEBandwidthType) {
        this.config.se_bandwidth_type = value;
    }

    /**
     * Method to sets default se_bandwidth_type when per_app is enabled.
     */
    public setDefaultSeBandwidthType(): void {
        this.seBandwidthType = SEBandwidthType.SE_BANDWIDTH_UNLIMITED;
    }

    /**
     * Tells if per_app is enabled.
     */
    public get isPerAppSeModeEnabled(): boolean {
        return Boolean(this.config.per_app);
    }

    /**
     * Method to sets max_vs_per_se when per_app is enabled.
     */
    public setDefaultMaxVsPerSe(): void {
        this.config.max_vs_per_se = 2;
    }

    /**
     * Method to sets max_num_se_dps when se_bandwidth_type changes.
     */
    public setMaxNumSeDps(): void {
        const { config } = this;

        switch (this.seBandwidthType) {
            case SEBandwidthType.SE_BANDWIDTH_200M:
                config.max_num_se_dps = 2;
                break;

            case SEBandwidthType.SE_BANDWIDTH_25M:
                config.max_num_se_dps = 1;
                break;
        }
    }

    /**
     * Tells if max_num_se_dps is disable based on se_bandwidth_type value.
     */
    public get isMaxNumSeDpsDisabled(): boolean {
        switch (this.seBandwidthType) {
            case SEBandwidthType.SE_BANDWIDTH_200M:
            case SEBandwidthType.SE_BANDWIDTH_25M:
                return true;

            default:
                return false;
        }
    }

    /**
     * Method to set defaults for SHARED_PAIR ha_mode.
     */
    public setDefaultsForSharedPairHaMode(): void {
        const { config } = this;

        config.min_scaleout_per_vs = 2;
        config.buffer_se = 0;
        config.algo = PlacementAlgorithm.PLACEMENT_ALGO_DISTRIBUTED;
        config.max_se = 10;
        config.max_vs_per_se = 10;
    }

    /**
     * Method to set defaults for SHARED ha_mode.
     */
    public setDefaultsForSharedHaMode(): void {
        const { config } = this;

        config.min_scaleout_per_vs = 1;
        config.buffer_se = 1;
        config.algo = PlacementAlgorithm.PLACEMENT_ALGO_PACKED;
        config.max_se = 10;
        config.max_vs_per_se = 10;
    }

    /**
     * Method to set defaults for LEGACY_ACTIVE_STANDBY ha_mode.
     */
    public setDefaultsForLegacyActiveStandbyHaMode(): void {
        const { config } = this;

        config.min_scaleout_per_vs = 1;
        config.buffer_se = 0;
        config.algo = PlacementAlgorithm.PLACEMENT_ALGO_PACKED;
        config.max_se = 2;
        config.auto_rebalance = false;
        config.max_vs_per_se = 10;
    }

    /**
     * Method to set License info using Cloud config, if not set.
     */
    public setLicenseInfo(cloudConfig: ICloud): void {
        const { config } = this;

        if (!config.license_tier) {
            config.license_tier = cloudConfig.license_tier;
        }

        if (!config.license_type) {
            config.license_type = cloudConfig.license_type;
        }
    }

    /**
     * Returns licenseType of SEGroup. Independent of Cloud's.
     */
    public getLicenseType(): string {
        return this.config.license_type;
    }

    /**
     * Returns the cloud_ref from data#config.
     */
    public getCloudRef(): string {
        return this.config.cloud_ref;
    }

    /**
     * Sets "extra_shared_config_memory" on change of the "Host Geolocation Profile" checkbox.
     * If change to true, set to 2000, else set to 0.
     */
    public setExtraConfigMemory(enable: boolean): void {
        this.config.extra_shared_config_memory = enable ? 2000 : 0;
    }

    /**
     * Returns the cloud UUID referenced in the SEGroup.
     */
    public get cloudId(): string {
        const { cloud_ref: cloudRef } = this.config;

        return this.stringService.slug(cloudRef);
    }

    /**
     * Reset auto_rebalance_capacity_per_se when Auto Rebalance is deselected.
     */
    public resetAutoRebalanceCapacityPerSe(): void {
        this.config.auto_rebalance_capacity_per_se = [];
    }

    /**
     * Reset Vip AutoScaleGroup Zones when Auto Rebalance is deselected.
     */
    public resetVipAsgZones(): void {
        this.vipAsg.configuration.resetZones();
    }

    /**
     * Reset the auto rebalance related fields once auto_rebalance checkbox is unchecked.
     */
    public resetAutoRebalance(): void {
        const { config } = this;
        const {
            auto_rebalance_interval: autoRebalanceInterval,
            min_cpu_usage: minCpuUsage,
            max_cpu_usage: maxCpuUsage,
        } = this.getDefaultConfig();

        config.auto_rebalance_interval = autoRebalanceInterval;
        config.min_cpu_usage = minCpuUsage;
        config.max_cpu_usage = maxCpuUsage;
        config.auto_rebalance_criteria = [];
        this.resetVipAsgZones();
        this.resetAutoRebalanceCapacityPerSe();
    }

    /**
     * Returns the vip_asg (Vip AutoScale Group).
     */
    public get vipAsg(): VipAutoscaleGroupConfigItem {
        return this.config.vip_asg;
    }

    /**
     * Gets a list of datastores for vCenter cloud. If an error occurs, returns an empty array.
     */
    public async getDatastores(): Promise<IDatastoreInfo[]> {
        const url = `${VIMGR_VCENTER_RUNTIME_DATASTORES_URL}/${this.cloudId}`;

        this.busy = true;
        this.errors = null;

        try {
            const response: IHttpResponse<IVIDatastore> = await this.request('GET', url);
            const { data } = response;

            // Backend is sending data in array format with required result at 1st index.
            if (Array.isArray(data) && data.length) {
                return data[0].datastores;
            }

            return [];
        } catch ({ data }) {
            this.errors = data;
        } finally {
            this.busy = false;
        }
    }

    /**
     * Gets a list of security groups, used for AWS and Openstack clouds.
     */
    public async getSecurityGroups(): Promise<ICloudSecurityGroup[]> {
        const api = `/api/cloud/${this.cloudId}/securitygroups`;

        this.busy = true;
        this.errors = null;

        try {
            const response: IHttpResponse<Icc_securitygroup_rsp> = await this.request('GET', api);
            const { securitygroups = [] } = response.data;

            return securitygroups;
        } catch ({ data }) {
            this.errors = data;
        } finally {
            this.busy = false;
        }
    }

    /**
     * Gets a list of Availability Zones, used for Openstack cloud.
     */
    public async getAvailabilityZones(): Promise<ICloudAZ[]> {
        const api = `/api/cloud/${this.cloudId}/availability-zones`;

        this.busy = true;
        this.errors = null;

        try {
            const response: IHttpResponse<Icc_zone_rsp> = await this.request('GET', api);
            const { zones = [] } = response.data;

            return zones;
        } catch ({ data }) {
            this.errors = data;
        } finally {
            this.busy = false;
        }
    }

    /**
     * Returns the configured Realtime SE Metrics object.
     */
    public get realtimeSeMetrics(): MessageItem<IMetricsRealTimeUpdate> {
        return this.config.realtime_se_metrics;
    }

    /**
     * Method to add Realtime SE Metrics Message Item.
     */
    public addRealtimeSeMetrics(): void {
        this.safeSetNewChildByField('realtime_se_metrics');
    }

    /**
     * Returns configured PlacementScopeConfig (Nsxt#Vcenters) Repated Message Item.
     */
    public get vcenters(): RepeatedMessageItem<PlacementScopeConfigConfigItem> {
        return this.config.vcenters;
    }

    /**
     * Returns the configured Vcenter clusters message item.
     */
    public get vcenterClusters(): VcenterClustersConfigItem {
        return this.config.vcenter_clusters;
    }

    /**
     * Returns the configured Vcenter hosts message item.
     */
    public get vcenterHosts(): VcenterHostsConfigItem {
        return this.config.vcenter_hosts;
    }

    /**
     * For OpenStack cloud,
     * To reset Availability Zones when scope option 'Any' is selected.
     */
    public resetAvailabilityZones(): void {
        this.config.openstack_availability_zones = [];
    }

    /**
     * Method to set Vcenter datastores value on add/update from modal.
     */
    public setVcenterDatastoreValue(datastoreValues: string[]): void {
        const { config } = this;

        config.vcenter_datastores = datastoreValues.map(
            (datastoreId: string) => ({ managed_object_id: datastoreId }),
        );
    }

    /**
     * Method to get Vcenter datastores value to be displayed in dropdown.
     */
    public getVcenterDatastoreValue(): IVcenterDatastore[] {
        return this.config.vcenter_datastores;
    }

    /**
     * Method to reset the Vcenter datastores info.
     */
    public resetVcenterDatastoresInfo(): void {
        const { config } = this;
        const {
            vcenter_datastores_include: datastoresInclude,
            vcenter_datastores: datastores,
        } = this.getDefaultConfig();

        config.vcenter_datastores_include = datastoresInclude;
        config.vcenter_datastores = datastores;
    }

    /**
     * Method to add User Agent Cache Config Message Item.
     */
    public addUserAgentCacheConfig(): void {
        this.safeSetNewChildByField('user_agent_cache_config');
    }

    /**
     * Opens child modal to add new Nsxt vCenter (PlacementScopeConfig) Message Item.
     */
    public addNsxtVcenter(modalBindings: Record<string, string[]>): void {
        this.addChildMessageItem({
            field: 'vcenters',
            modalBindings: {
                cloudId: this.cloudId,
                ...modalBindings,
            },
        });
    }

    /**
     * Opens child modal to edit a Nsxt vCenter (PlacementScopeConfig) Message Item.
     */
    public editNsxtVcenter(
        nsxtVcenter: PlacementScopeConfigConfigItem,
        modalBindings: Record<string, string[]>,
    ): void {
        this.editChildMessageItem({
            field: 'vcenters',
            messageItem: nsxtVcenter,
            modalBindings: {
                editMode: true,
                cloudId: this.cloudId,
                ...modalBindings,
            },
        });
    }

    /**
     * Method to remove a Nsxt vCenter (PlacementScopeConfig) Message Item.
     */
    public removeNsxtVcenter(nsxtVcenter: PlacementScopeConfigConfigItem): void {
        this.vcenters.removeByMessageItem(nsxtVcenter);
    }

    /**
     * Returns configured custom_tag Repated Message Item.
     */
    public get customTags(): RepeatedMessageItem<MessageItem<ICustomTag>> {
        return this.config.custom_tag;
    }

    /**
     * Adds a new custom_tag Message Item.
     */
    public addCustomTag(): void {
        this.customTags.add();
    }

    /**
     * Removes the given custom_tag Message Item.
     */
    public removeCustomTag(customTag: MessageItem<ICustomTag>): void {
        this.customTags.removeByMessageItem(customTag);
    }

    /**
     * Method to sets Override Data Network key based on Cloud type.
     */
    public setOverrideDataNetwork(keyByCloudType: string, dataNetworkRef: string): void {
        this.config[keyByCloudType] = dataNetworkRef;
    }

    /**
     * Method to reset the Instance Flavor value, when "Auto Select" option is choosen.
     */
    public resetInstanceFlavor(): void {
        this.config.instance_flavor = undefined;
    }

    /**
     * Saves VRF Context object to server if Static Route is set.
     */
    public saveVRFContext(): IPromise<IVrfContext> {
        const { staticRoute, vrfContext } = this;

        if (isUndefined(staticRoute.next_hop.addr)) {
            const index = vrfContext.static_routes.indexOf(staticRoute);

            if (index > -1) {
                vrfContext.static_routes.splice(index, 1);
            }
        }

        return this.request('PUT', vrfContext.url, vrfContext)
            .then(({ data }: IHttpResponse<IVrfContext>) => this.vrfContext = data);
    }

    /** @override */
    public save(appendToCollection?: boolean):
    IPromise<IHttpResponse<IServiceEngineGroup>> {
        const save = super.save(appendToCollection);

        if (this.hasOverrideDataNetwork) {
            const deferred = this.$q.defer<IHttpResponse<IServiceEngineGroup>>();

            this.saveVRFContext().then(() => {
                save.then(r => deferred.resolve(r), r => deferred.reject(r));
            }, error => {
                this.errors = error.data;
                deferred.reject(error);

                return error;
            }).finally(() => this.busy = false);

            return deferred.promise;
        } else {
            return save;
        }
    }

    /** @override */
    public dataToSave(): IServiceEngineGroup {
        const config = super.dataToSave();

        if (!isUndefined(config.instance_flavor)) {
            const { instance_flavor: instanceFlavor } = config;

            /**
             * We are saving name here rather than id but for SEGroupFlavor instance name
             * is used for both: id and (surprise!) name, hence ref looks like "name#name".
             */
            config.instance_flavor = this.stringService.slug(instanceFlavor);
        }

        // Delete the related config fields when datastore_mode is not shared.
        switch (config.vcenter_datastore_mode) {
            case VcenterDatastoreMode.VCENTER_DATASTORE_ANY:
            case VcenterDatastoreMode.VCENTER_DATASTORE_LOCAL:
                delete config.vcenter_datastores_include;
                delete config.vcenter_datastores;
                break;
        }

        // For openstack cloud, if AZ is empty then delete the related key.
        if (isEmpty(config.openstack_availability_zones)) {
            delete config.openstack_availability_zones;
        }

        const { openstack_mgmt_network_uuid: osMgmtNetId } = config;

        if (osMgmtNetId) {
            config.openstack_mgmt_network_name = this.stringService.name(osMgmtNetId);
            config.openstack_mgmt_network_uuid = this.stringService.slug(osMgmtNetId);
        } else {
            delete config.openstack_mgmt_network_name;
        }

        const {
            vip_asg: vipAsg,
            auto_rebalance: autoRebalance,
            auto_rebalance_criteria: autoRebalanceCriteria,
            data_network_id: dataNetworkId,
        } = config;

        if (!autoRebalance || isEmpty(vipAsg)) {
            delete config.vip_asg;
        } else if (!autoRebalanceCriteria[0]) {
            delete config.auto_rebalance_criteria;
            delete config.auto_rebalance_capacity_per_se;
        }

        // If data_network_id is set, backend expects just the id part.
        if (dataNetworkId) {
            config.data_network_id = this.stringService.slug(dataNetworkId);
        }

        // Remove vcenter_clusters if cluster_refs is empty or doesn't exists.
        if (!config.vcenter_clusters.cluster_refs ||
            config.vcenter_clusters.cluster_refs.length < 1) {
            delete config.vcenter_clusters;
        }

        // Remove vcenter_hosts if host_refs is empty or doesn't exists.
        if (!config.vcenter_hosts.host_refs || config.vcenter_hosts.host_refs.length < 1) {
            delete config.vcenter_hosts;
        }

        return config;
    }

    /** @override */
    public getUpgradeStatusConfig(): IUpgradeStatusInfo {
        return this.getInventoryData()?.upgradestatus || {};
    }

    /** @override */
    protected beforeEdit(): void {
        const { config } = this;

        this.setVrfContextAndStaticRoute();

        config.service_ip_subnets = config.service_ip_subnets || [];

        this.setOpenStackMgmtNetworkRef();

        config.auto_rebalance_criteria = config.auto_rebalance_criteria || [];

        // Backend supports multiple records only first one is taken into account.
        if (config.auto_rebalance_criteria.length > 1) {
            config.auto_rebalance_criteria.length = 1;
        }

        config.auto_rebalance_capacity_per_se = config.auto_rebalance_capacity_per_se || [];

        // Backend supports multiple records only first one is taken into account.
        if (config.auto_rebalance_capacity_per_se.length > 1) {
            config.auto_rebalance_capacity_per_se.length = 1;
        }

        if (!config.vip_asg) {
            this.safeSetNewChildByField('vip_asg');
        }

        if (!config.vcenter_clusters) {
            this.safeSetNewChildByField('vcenter_clusters');
        }

        if (!config.vcenter_hosts) {
            this.safeSetNewChildByField('vcenter_hosts');
        }

        // If data_network_id is set for a SE Group, then resolve the network name by its ref.
        if (config.data_network_id) {
            this.setDataNetworkRef();
        }
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(globalL10nKeys.serviceEngineGroupLabel);
    }

    /**
     * Returns true if Override Data Network is set for Azure cloud or VCenter cloud.
     */
    private get hasOverrideDataNetwork(): boolean {
        const { config } = this;

        return Boolean(config.data_network_id) || Boolean(config.mgmt_network_ref);
    }

    /**
     * Method to load VRF Context and then set static_routes.
     */
    private async setVrfContextAndStaticRoute(): Promise<void> {
        const url = `${VRF_CONTEXT_MGMT_URL}&cloud_uuid=${this.cloudId}`;

        try {
            const response = await this.request('GET', url);
            const { data } = response;

            if (data && data.count) {
                [this.vrfContext] = data.results as IVrfContext[];

                const emptyStaticRoute: IStaticRoute = {
                    next_hop: {
                        addr: undefined,
                    },
                };
                const { static_routes: staticRoutes } = this.vrfContext;

                if (!staticRoutes || !staticRoutes.length) {
                    this.vrfContext.static_routes = [emptyStaticRoute];
                }

                this.staticRoute = this.findStaticRoute();

                if (!this.staticRoute && staticRoutes.length) {
                    this.vrfContext.static_routes.push(emptyStaticRoute);

                    this.staticRoute = this.findStaticRoute();
                }
            }
        } catch (error) {
            this.errors = error.data;
        }
    }

    /**
     * Returns single Static Route object based on current SE Group uuid.
     */
    private findStaticRoute(): IStaticRoute | null {
        if (this.vrfContext) {
            const staticRoutes = this.vrfContext.static_routes;
            const { uuid } = this.config;

            if (Array.isArray(staticRoutes)) {
                for (const route of staticRoutes) {
                    if (!route.route_id || route.route_id === uuid) {
                        this.modifyStaticRoute(route);

                        return route;
                    }
                }
            }
        }

        return null;
    }

    /**
     * Adds additional properties to Static Route for VRF Context API to work.
     */
    private modifyStaticRoute(route: IStaticRoute): void {
        const { uuid } = this.config;

        route.next_hop.type = IpAddrType.V4;
        route.route_id = uuid;

        const getSubnetObject = this.getAjsDependency_('getSubnetObject');

        route.prefix = getSubnetObject('0.0.0.0/0');
    }

    /**
     * Method to set data_network_id as ref of selected data network using its uuid.
     */
    private async setDataNetworkRef(): Promise<void> {
        const { config, cloudId } = this;
        const { data_network_id: dataNetworkId } = config;

        const network = new this.SubnetListNetwork({
            id: dataNetworkId,
            cloudId,
        });

        try {
            await network.load();
            config.data_network_id = network.getRef();
        } catch (err) {
            this.devLoggerService.warn(`Could not load network with id "${dataNetworkId}"`);
        } finally {
            network.destroy();
        }
    }

    /**
     * To set openstack_mgmt_network_uuid as ref of selected management network using its uuid.
     */
    private async setOpenStackMgmtNetworkRef(): Promise<void> {
        const { config, cloudId } = this;
        const {
            openstack_mgmt_network_name: osMgmtNetName,
            openstack_mgmt_network_uuid: osMgmtNetId,
        } = config;

        if (osMgmtNetName) {
            if (osMgmtNetId) {
                config.openstack_mgmt_network_uuid = `/${osMgmtNetId}#${osMgmtNetName}`;
            } else {
                const osNetworkCollection = new this.SubnetListNetworkCollection({
                    params: {
                        discovered_only: true,
                        cloud_uuid: cloudId,
                        name: osMgmtNetName,
                    },
                });

                try {
                    await osNetworkCollection.load();

                    // if it hasn't been updated by user yet then set its previous value correctly.
                    if (!config.openstack_mgmt_network_uuid) {
                        if (osNetworkCollection.getNumberOfItems()) {
                            const [network] = osNetworkCollection.items;

                            config.openstack_mgmt_network_uuid = network.getRef();
                        } else {
                            delete config.openstack_mgmt_network_name;
                        }
                    }
                } catch (err) {
                    // eslint-disable-next-line max-len
                    this.devLoggerService.warn(`Could not load management network "${osMgmtNetName}"`);
                } finally {
                    osNetworkCollection.destroy();
                }
            }
        }
    }
}

export const SEGroup = withUpgradeStatusMixin(SEGroupItem);

// Export instance type ITSEGroup for type annotation use only due to the fact that SEGroup class
// can't be used that way since it's a generated intermidiate class and it works as a value.
export type ITSEGroup = InstanceType<typeof SEGroup>;
