/**
 * @module HealthMonitorModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import {
    HealthMonitorHttp,
    HealthMonitorUdp,
} from 'object-types';

import {
    HealthMonitorType,
    IHealthMonitorTcp,
    IHealthMonitorUdp,
} from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';

import {
    HealthMonitorHttpConfigItem,
    HealthMonitorItem,
} from 'ajs/modules/healthmonitor/factories';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import * as l10n from './health-monitor-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Health Monitor Modal Component.
 *
 * @author Rajawant Prajapati
 */
@Component({
    selector: 'health-monitor-modal',
    templateUrl: './health-monitor-modal.component.html',
})
export class HealthMonitorModalComponent implements OnInit {
    /**
     * Health Monitor ObjectTypeItem instance.
     */
    @Input()
    public editable: HealthMonitorItem;

    /**
     * HealthMonitor object type.
     */
    public objectType: string;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for Health Monitor Modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Object types used in template.
     */
    public readonly objectTypes = {
        HealthMonitorHttp,
        HealthMonitorUdp,
    };

    /**
     * Hash of Health Monitor Types.
     */
    public readonly healthMonitorTypesHash = {
        HEALTH_MONITOR_DNS: HealthMonitorType.HEALTH_MONITOR_DNS,
        HEALTH_MONITOR_EXTERNAL: HealthMonitorType.HEALTH_MONITOR_EXTERNAL,
        HEALTH_MONITOR_RADIUS: HealthMonitorType.HEALTH_MONITOR_RADIUS,
        HEALTH_MONITOR_SIP: HealthMonitorType.HEALTH_MONITOR_SIP,
        HEALTH_MONITOR_HTTP: HealthMonitorType.HEALTH_MONITOR_HTTP,
        HEALTH_MONITOR_HTTPS: HealthMonitorType.HEALTH_MONITOR_HTTPS,
        HEALTH_MONITOR_TCP: HealthMonitorType.HEALTH_MONITOR_TCP,
        HEALTH_MONITOR_UDP: HealthMonitorType.HEALTH_MONITOR_UDP,
        HEALTH_MONITOR_SCTP: HealthMonitorType.HEALTH_MONITOR_SCTP,
    };

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for sub config of HTTP/HTTPS Health Monitor Types.
     */
    public get healthMonitorTypeConfig(): HealthMonitorHttpConfigItem {
        const { healthMonitorTypeConfig } = this.editable;

        return healthMonitorTypeConfig;
    }

    /**
     * Getter for TCP Monitor message item.
     */
    public get tcpMonitor(): MessageItem<IHealthMonitorTcp> {
        const { tcpMonitor } = this.editable;

        return tcpMonitor;
    }

    /**
     * Getter for UDP Monitor message item.
     */
    public get udpMonitor(): MessageItem<IHealthMonitorUdp> {
        const { udpMonitor } = this.editable;

        return udpMonitor;
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }
}
